/**
 * Site header
 */
 .site-name{
  //border-top: 0px solid $grey-color-dark;
  // border-bottom: 1px solid $grey-color-light;
  min-height: $spacing-unit * 1.865;
  display: inline-block;
  margin: 0px;

}

 .site-header {
  border-top: 0px solid $grey-color-dark;
  border-bottom: 0px solid $grey-color-light;
  min-height: $spacing-unit * 1.5;

  // Positioning context for the mobile navigation icon
  //position: relative;
  //overflow: hidden;
  //background: rgba(255, 255, 255, 0.9);
  background: rgba(107, 242, 22, 0.9);
  //position:sticky;
  //max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
  //max-width:         calc(#{$content-width} - (#{$spacing-unit} * 2));
  position: fixed;
  width: 100%
}


.site-title {
  @include relative-font-size(1.625);
  font-weight: 300;
  line-height: $base-line-height * $base-font-size * 2;
  letter-spacing: 1px;
  margin-bottom: 0;
    
  &,
  &:visited {
    color: $grey-color-dark;
  }
  &:hover {
    //background-color: #FFF04D;
    background-color: #ff00e6;
    color: #000000;
  }
}

.site-nav {
  float: right;
  line-height: $base-line-height * $base-font-size * 2.1;
  .nav-trigger {
      display: none;
  }

  .menu-icon {
    display: none;
  }

  .page-link {
    color: $text-color;
    line-height: $base-line-height;
    text-transform: uppercase;
    // Gaps between nav items, but not on the last one
    &:not(:last-child) {
      margin-right: 20px;
    }
    &:hover {
      background-color: #ff00e6;
      color: #000000;
    }
    
  }
  
  @include media-query($on-palm) {
    position: absolute;
    top: 3px;
    right: $spacing-unit / 2;
    background-color: $background-color;
    border: 1px solid $grey-color-light;
    border-radius: 5px;
    text-align: right;

    label[for="nav-trigger"] {
      display: block;
      float: right;
      width: 40px;
      height: 40px;
      z-index: 2;
      cursor: pointer;
    }

    .menu-icon {
      display: block;
      float: right;
      width: 40px;
      height: 26px;
      line-height: 0;
      padding-top: 12px;
      text-align: center;

      > svg {
        fill: $grey-color-dark;
      }
    }

    input ~ .trigger {
      clear: both;
      display: none;
    }

    input:checked ~ .trigger {
      display: block;
      padding-bottom: 5px;
    }

    .page-link {
      display: block;
      padding: 5px 10px;

      &:not(:last-child) {
        margin-right: 0;
      }
      margin-left: 20px;
    }
  }
}



/**
 * Site footer
 */
.site-footer {
  border-top: 1px solid  rgb(107, 242, 22);
  padding: $spacing-unit 0;
  a {
    color: #ff00e6;
  }
}

.footer-heading {
  @include relative-font-size(1.125);
  margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}

.footer-col-wrapper {
  @include relative-font-size(0.9375);
  color: $grey-color;
  margin-left: -$spacing-unit / 2;
  @extend %clearfix;
}

.footer-col {
  float: left;
  margin-bottom: $spacing-unit / 2;
  padding-left: $spacing-unit / 2;
}

.footer-col-1 {
  width: -webkit-calc(33% - (#{$spacing-unit} / 2));
  width:         calc(33% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
  width: -webkit-calc(33% - (#{$spacing-unit} / 2));
  width:         calc(33% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
  width: -webkit-calc(33% - (#{$spacing-unit} / 2));
  width:         calc(33% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
  .footer-col-1,
  .footer-col-2 {
    width: -webkit-calc(50% - (#{$spacing-unit} / 2));
    width:         calc(50% - (#{$spacing-unit} / 2));
  }

  .footer-col-3 {
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}

@include media-query($on-palm) {
  .footer-col {
    float: none;
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}



/**
 * Page content
 */
.page-content {
  padding: $spacing-unit 0;
  padding-top: $spacing-unit * 2.5;
  flex: 1;
  a {
    color: #ff00e6;
  }
}

.page-heading {
  @include relative-font-size(2);
}

.post-list-heading {
  @include relative-font-size(1.75);
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit;
  }
}

.post-meta {
  font-size: $small-font-size;
  color: $grey-color;
}

.post-link {
  display: block;
  @include relative-font-size(1.5);
}



/**
 * Posts
 */
.post-header {
  margin-bottom: $spacing-unit;
}

.post-title {
  @include relative-font-size(2.625);
  letter-spacing: -1px;
  line-height: 1;
  text-transform: lowercase;
  @include media-query($on-laptop) {
    @include relative-font-size(2.25);
  }
}

.post-content {
  margin-bottom: $spacing-unit;

  h2 {
    @include relative-font-size(2);

    @include media-query($on-laptop) {
      @include relative-font-size(1.75);
    }
  }

  h3 {
    @include relative-font-size(1.625);
    @include media-query($on-laptop) {
      @include relative-font-size(1.375);
    }
  }

  h4 {
    @include relative-font-size(1.5);
    @include media-query($on-laptop) {
      @include relative-font-size(1.125);
    }
    text-transform: uppercase;
  }

  //h5 is same as h4, but with no bottom margin, to enable a subtitle
  h5 {
    @include relative-font-size(1.5);
    margin-bottom: 0;
    @include media-query($on-laptop) {
      @include relative-font-size(1.125);
    }
    text-transform: uppercase;
  }

  //h6 is same as default text, buyt with a bottom margin matching h4, for subtitles
  h6 {
    @include relative-font-size(1);
    @include media-query($on-laptop) {
      @include relative-font-size(1.125);
    }
  }
}

//styles for lines

hr.line1 {
	border-top: 2px solid rgb(107, 242, 22);
	border-bottom: 1px solid rgb(107, 242, 22);
  margin-top: $spacing-unit * 1;
  margin-bottom: $spacing-unit * 1;
}

hr.line2 {
	border-top: 2px solid  rgb(107, 242, 22);
	border-bottom: 1px solid  rgb(107, 242, 22);
  margin-top: $spacing-unit * 0;
  margin-bottom: $spacing-unit * 1;
}

/**
 * CSS for contact form
 */


 .contact-section {
  width: 100%;
  max-width: 50rem;
  margin-left: 0;
  margin-right: 0;
  padding: 0rem 0rem;
}

.contact-intro > * + * {
  margin-top: 1rem;
}

.contact-title {
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 700;
}

.contact-description {
  color: rgb(107 114 128);
}

.form-group-container {
  display: grid;
  gap: 1rem;
  margin-top: 0rem;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-label {
  margin-bottom: 0.5rem;
}

.form-input,
.form-textarea {
  padding: 0.5rem;
  border: 1px solid #e5e7eb;
  display: flex;
  height: 2.5rem;
  width: 100%;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.form-input::placeholder,
.form-textarea:focus-visible {
  color: #6b7280;
}

.form-input:focus-visible,
.form-textarea:focus-visible {
  outline: 2px solid #2563eb;
  outline-offset: 2px;
}

.form-textarea {
  min-height: 120px;
}

.form-submit {
  width: 100%;
  margin-top: 1.2rem;
  background-color: #ff00e6;
  color: #fff;
  padding: 13px 5px;
  border-radius: 0.375rem;
}
